import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
                <div className="col-md-8 col-md-offset-2 intro-text">
                    <img src="img/logoduze.png" className="img-logo" alt="Instalacje dla Twojego domu"/>
                    <p>{props.data ? props.data.paragraph : "jeszcze chwila..."}</p>
                    <p>{props.data ? props.data.paragraph2 : "jeszcze chwila..."}</p>
                    <a
                        href="#services"
                        className="btn btn-custom btn-lg page-scroll"
                    >
                        Dowiedz się więcej
                    </a>{" "}
                </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
